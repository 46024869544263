import React from "react";
import CategorySection from "./categorySection";
import ProductSuggetions from "../product/productSuggest";
import CategorySuggetions from "../product/categorySuggest";
import SerachBox from "./searchbox";
import { Helmet } from "react-helmet";


const Home = ({ user, isproPage }) => {

    return (
        <>
            <Helmet>
                <title>
                    Agribusiness platform, Agri inputs, output & services,
                    High-quality agri inputs & farm machineries, extension services for farmers,
                    agribusiness platform for stakeholders
                </title>
                <meta
                    name="description"
                    content="Agri inputs, Agri output and Agri services: High-quality fertilizers, pesticides, 
                farm machinery, implements & expert consultancy for sustainable farming"
                />
                <meta
                    name="keywords"
                    content="Agri Inputs, Agri Output, Agri services, Agribusiness platform,
                    one-stop agri solutions for farmers, best quality agricultural produce, 
                    opportunities for agri service providers to connect with farmers"
                />
                <meta
                    name="robots"
                    content="index, follow"
                />
                <meta
                    property="og:description"
                    content="Karnataka Agribusiness is a comprehensive platform connecting farmers, vendors, 
                    and service providers in a big way in the agricultural ecosystem. 
                    Designed to support modern farming needs, our platform delivers high-quality agri inputs, 
                    facilitates the efficient management of agri outputs, and offers expert agri services for 
                    stakeholders. As a one-stop agribusiness platform, we are committed to providing resources, 
                    opportunities, and connections for sustainable farming practices while enhancing productivity 
                    and profitability for all users."
                />
            </Helmet>

            {/* Hero Section */}
            <section>
                <img
                    src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1735623001/KAB-web-banner-update_es9uvx.png"
                    alt="home.pic"
                    className="object-cover"
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                />
            </section>

            <div className="m-5 text-sm bg-white shadow-md rounded-md dark:bg-boxdark">
                <div className="p-5">
                    <p className="mb-3 text-justify">
                        In the agricultural ecosystem, Karnataka Agribusiness is a complete platform that links
                        farmers, suppliers, and service providers. It helps farmers increase production and
                        profitability by providing a wide range of products and services, such as premium
                        fertilizers, insecticides, farm equipment, seeds, and plants. In order to improve market
                        access and ensure transparent transactions, the platform also makes it easier to sell
                        agricultural products including fruits, vegetables, and groceries.
                    </p>
                    <p className="text-justify">
                        Karnataka Agribusiness builds a cooperative and sustainable environment by combining
                        agricultural inputs, products, and services. On-farm services, marketing assistance,
                        and professional consulting are available to farmers, and suppliers and service
                        providers gain access to a larger clientele. The user experience is improved by the
                        platform's user-centric features, such as tailored product recommendations and simple
                        navigation. Karnataka Agribusiness prioritizes sustainability, trust, and openness in
                        order to empower stakeholders and drive agricultural success, creating a vibrant and
                        efficient agricultural network.
                    </p>
                </div>
            </div>

            <SerachBox />

            {/* Category Sections */}
            <div>
                <CategorySection />
            </div>

            {/* Product You May Like Sections */}
            <div>
                <ProductSuggetions user={user} isproPage={isproPage} />
            </div>

            {/* Categories You May Like Sections */}
            <div>
                <CategorySuggetions />
            </div>
        </>
    );
};

export default Home;
