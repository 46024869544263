import React, { useEffect, useState } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import Spinner from "../product/loading";
import VendorEnquiry from "../home/vendorEnquiry";
import { Link, useNavigate, useParams } from "react-router-dom";
import Toast from "../../commons/Toastify";
import { Link1SEO } from "./SEO";



const LinkProducts1 = ({ user }) => {
    const { slug } = useParams();  // Get all params: slug, subslug, subsubslug
    const formattedSlug = slug.replace(/(?<!^)(?=[A-Z])/g, " ");

    const [searchTerm, setSearchTerm] = useState(slug || '');
    const [currentPage, setCurrentPage] = useState(1);
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(12); // Number of products per page
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [isVendorEnquiryOpen, setIsVendorEnquiryOpen] = useState(false);
    const [vendorID, setVendorID] = useState('');
    const [categoryID, setCategoryID] = useState('');
    const [productName, setProductName] = useState('');

    const [viewPhone, setViewPhone] = useState(null);
    const [toast, setToast] = useState({ show: false, type: '', title: '', message: '' });
    const navigate = useNavigate();


    useEffect(() => {
        if (slug) {
            setSearchTerm(slug);
        }
    }, [slug])

    useEffect(() => {
        fetchProducts();
    }, [currentPage, searchTerm]);

    const fetchProducts = async () => {
        setLoading(true);
        setError(null);

        try {
            const queryParams = new URLSearchParams({
                page: currentPage,
                size: itemsPerPage,
                searchString: searchTerm,
            }).toString();

            const response = await fetch(`${process.env.REACT_APP_API_URL_PRO}/products?${queryParams}`);
            const serRes = await response.json();

            if (response.ok) {
                setProducts(serRes.data || []);
                setTotalPages(serRes.pagination ? serRes.pagination.totalPages : 1);

            } else {
                setError(serRes.message || "Failed to fetch products");
            }

        } catch (err) {
            setError(err.message || "Something went wrong!");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner />; // Show spinner while loading
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // Delay the scroll action slightly
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 0); // You can adjust the delay if necessary
    };

    // Function to get the token from local storage
    const getToken = () => localStorage.getItem('token');

    const viewNumber = (productId) => {
        const token = getToken();

        if (!token) {
            // Show the success toast
            setToast({
                show: true,
                type: 'info',
                title: 'info',
                message: 'Please Login First!',
            });

            // Show the success toast for 1 seconds, then navigate
            setTimeout(() => {
                navigate('/login');
            }, 1000);
            return;
        }
        setViewPhone(prevId => prevId === productId ? null : productId);
    };

    const sendEnquiry = (vendorID, categoryID, title) => {
        const token = getToken();

        if (!token) {
            // Show the success toast
            setToast({
                show: true,
                type: 'info',
                title: 'info',
                message: 'Please Login First!',
            });

            // Show the success toast for 1 seconds, then navigate
            setTimeout(() => {
                navigate('/login');
            }, 1000);
            return;
        }

        setIsVendorEnquiryOpen(true);
        setVendorID(vendorID);
        setCategoryID(categoryID);
        setProductName(title);
    };


    const handleClick = (productId) => {
        localStorage.setItem('productId', productId);
    };


    return (
        <>
            <Link1SEO slug={slug} />
            {slug && slug === 'AgriInput' ? (
                <div className="m-5 text-sm bg-white shadow-md rounded-md dark:bg-boxdark">
                    <div className="p-5">
                        <p className="mb-3 text-justify">
                            A comprehensive platform, Karnataka Agribusiness provides farmers with excellent
                            agricultural solutions to support them on their journey to profitable and
                            sustainable farming. We offer high-yield hybrid seeds, organic bio-fertilizers,
                            and efficient irrigation systems. We prioritize soil health and ensure that nutrients
                            are maintained appropriately for optimal crop growth with fertilizers such as
                            Dr. Soil Health, Amruth Microbial Consortia, Multiplex Annapurna and Bio NPK.
                            With our effective pest management products, we assist farmers and FPOs in protecting
                            their crops from harmful pests.
                        </p>
                        <p className="text-justify">
                            Selection of agricultural equipment, which includes power tillers, brush cutters,
                            and sprayers from well-known manufacturers like Honda, Texas and Makita, increases
                            farming productivity and lowers manual labor costs. To make post-harvest operations
                            more efficient, the platform also provide primary processing equipment. Our goal at
                            Karnataka Agribusiness is to provide farmers with the greatest equipment, materials,
                            and technology possible so they can increase production, and use sustainable
                            agricultural methods. From crop cultivation to harvesting, our platform seeks to
                            satisfy the many demands of farmers, and creating opportunity for suppliers of
                            agri inputs and services.
                        </p>
                    </div>
                </div>
            )
                : slug === 'AgriOutput' ? (
                    <div className="m-5 text-sm bg-white shadow-md rounded-md dark:bg-boxdark">
                        <div className="p-5">
                            <p className="mb-3 text-justify">
                                Karnataka Agribusiness Platform is a one-stop shop for linking farmers-FPOs
                                and consumers in the agribusiness ecosystem. The website guarantees quick
                                access to high-quality product by providing a large selection of fresh fruits,
                                vegetables, and consumables that are directly obtained from nearby farms.
                                Customers may take advantage of fresher, more reasonably priced options while
                                also helping the local economy by purchasing seasonal fruits like mangoes,
                                vegetables and groceries. The platform is a great option for everyday purchasing
                                needs because it provides critical commodities like pulses, grains, and locally
                                produced spices in addition to fresh vegetables.
                            </p>
                            <p className="text-justify">
                                Customers that buy locally benefit from better taste, the freshest produce
                                possible, and sustainability because the produce is picked at its prime and
                                delivered fast. Buyers may easily submit criteria, connect with vendors,
                                compare prices, and finalize transactions thanks to the user-friendly site.
                                Karnataka Agribusiness makes farm-fresh produce accessible, encouraging a
                                better lifestyle and directly helping local farmers, whether you live in
                                Bangalore or smaller towns. With Karnataka Agribusiness, enjoy the freshness
                                and ease of locally sourced food right now!
                            </p>
                        </div>
                    </div>
                )
                    : (
                        <div className="m-5 text-sm bg-white shadow-md rounded-md dark:bg-boxdark">
                            <div className="p-5">
                                <p className="mb-3 text-justify">
                                    Karnataka Agribusiness offers a comprehensive platform designed to support
                                    farmers and vendors with essential services that enhance agricultural
                                    practices. Karnataka Agribusiness provide detailed project reports to
                                    evaluate the financial feasibility of farming operations, assisting farmers
                                    in securing bank funding and government subsidies for commercial farms.
                                    The Technology identification services help farmers adopt modern agricultural
                                    technologies such as irrigation systems and machinery, boosting productivity
                                    and efficiency. In addition to technical support, the platform offers
                                    marketing assistance to connect farmers with profitable markets, ensuring
                                    better supply chain management and enhanced profitability.
                                </p>
                                <p className="text-justify">
                                    Agribusiness consultancy services guide farmers from optimizing production
                                    to increasing their overall profits. The crop planning services, based on
                                    soil health, weather patterns, and market demands, help farmers achieve high
                                    yields while maintaining sustainable farming practices. The crop selection
                                    guide aids farmers in choosing the right crops suited to their land and
                                    market trends. With pooling of right service providers, the platform
                                    emphasizes eco-friendly and sustainable farming practices, ensuring long-term
                                    success and profitability for farmers and vendors. The goal is to bridge
                                    traditional farming methods with modern technological advancements, driving
                                    agricultural growth across Karnataka.
                                </p>
                            </div>
                        </div>
                    )}

            <div className="lg:mx-50 my-5 bg-white dark:bg-boxdark-2 shadow-md dark:shadow-none">
                <div className="bg-success text-white">
                    <h4 className="text-center p-2 font-bold">{slug && formattedSlug}</h4>
                </div>
                {/* Product List */}
                <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 mx-auto gap-4 p-5">
                    {products.map((item, i) => (
                        <div key={i + 1} className="bg-white dark:bg-boxdark shadow-lg rounded-lg flex flex-col p-1 lg:w-50 text-sm cursor-pointer">
                            <Link to={`/view/${item.title.replace(/\s+/g, '-')}`} className="block flex-grow"
                                onClick={handleClick.bind(this, item._id)}
                            >
                                <div className="text-center">
                                    <div className="overflow-hidden">
                                        <img
                                            src={item.images}
                                            alt="Product"
                                            className="mx-auto mb-2 transform hover:scale-125 transition-transform duration-300"
                                            style={{ width: '60%', height: '100px' }}
                                        />
                                    </div>
                                    <h3 className="font-semibold mb-1">{item.title}</h3>
                                    <p className="mb-1">{item.brand}</p>
                                    <p className="font-bold mb-2">₹{item.price}
                                        {item.quantity ? '/' : ''}
                                        <span className="text-success ms-1">
                                            {item.quantity && item.quantity}
                                        </span>
                                        {item.instock !== undefined && item.instock !== null && item?.categoryID?.name !== 'AgriOutput' &&
                                            item?.categoryID?.name !== 'AgriServices' ? (
                                            <span>
                                                <span className="mx-1 text-xl font-bold">{item.instock ? '-' : ''}</span>
                                                <span className={`${typeof item.instock === 'number' && item.instock > 0 ? 'text-success' : 'text-danger'}`}>
                                                    {typeof item.instock === 'number' && item.instock > 0 ? 'In Stock' : 'Out of Stock'} {item.instock ? item.instock : ''}
                                                </span>
                                            </span>
                                        ) : null}
                                    </p>
                                    <p className="line-through text-danger">
                                        {item.cutprice ? `₹${item.cutprice}` : ''}
                                    </p>
                                </div>
                            </Link>
                            <div className="text-center mt-2">
                                <button
                                    className="flex items-center font-bold lg:px-8 px-5 py-1 rounded text-amber-500 hover:text-blue-500"
                                    onClick={() => viewNumber(item._id)}
                                >
                                    <BiSolidPhoneCall className="mr-2" />
                                    <span>
                                        {viewPhone === item._id ? item.phone : "View Number"}
                                    </span>
                                </button>

                                <button
                                    className="bg-green-500 text-white px-4 py-2 font-bold w-full rounded hover:bg-green-600 transition mt-2"
                                    onClick={() => sendEnquiry(item.vendorID, item.categoryID, item.title)}
                                >
                                    Get Best Price
                                </button>
                            </div>
                        </div>
                    ))}

                </div>

                {/* Pagination section */}
                <div className={`flex items-center justify-center pb-5`}>
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1} // Disable if on the first page
                        className={`px-4 py-2 border rounded ${currentPage === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-white text-blue-500'}`}
                    >
                        Previous
                    </button>

                    <span className={`mx-4`}>
                        Page {currentPage} of {totalPages}
                    </span>

                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages || totalPages === 0} // Disable if on the last page
                        className={`px-4 py-2 border rounded ${currentPage === totalPages || totalPages === 0 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-white text-blue-500'}`}
                    >
                        Next
                    </button>
                </div>


                {/* Vendor Enquiry */}
                {isVendorEnquiryOpen && (
                    <VendorEnquiry
                        onCancel={() => setIsVendorEnquiryOpen(false)}
                        onConfirm={() => setIsVendorEnquiryOpen(false)}
                        vendorID={vendorID}
                        categoryID={categoryID}
                        buyerID={user._id}
                        productName={productName}
                    />
                )}


                {/* Toast notification */}
                {toast.show && (
                    <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50">
                        <Toast type={toast.type} title={toast.title} message={toast.message} onClose={() => setToast({ show: false })} />
                    </div>
                )}
            </div>
        </>
    );
};

export default LinkProducts1;
