import React from "react";
import AllfpoList from "./allFPOlist";


const HomeFPO = () => {
    return (
        <>
            <AllfpoList />
        </>
    )
};
export default HomeFPO;